<template>
  <div class="relative px-4 pt-6 sm:px-6 lg:px-8">
    <nav
      class="relative flex items-center justify-between sm:h-10 lg:justify-start"
      aria-label="Global"
    >
      <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
        <div class="flex items-center justify-between w-full md:w-auto">
          <router-link to="home">
            <span class="sr-only">Workflow</span>
            <img class="w-auto h-8 sm:h-10" src="@/assets/icon.png" />
          </router-link>
          <div class="flex items-center -mr-2 md:hidden">
            <button
              type="button"
              class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              id="main-menu"
              aria-haspopup="true"
            >
              <span class="sr-only">Open main menu</span>
              <!-- Heroicon name: menu -->
              <svg
                class="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
        <router-link
          to="chi-siamo"
          class="font-medium text-gray-500 hover:text-gray-900"
          >Chi siamo</router-link
        >

        <router-link
          to="patologie"
          class="font-medium text-gray-500 hover:text-gray-900"
          >Patologie</router-link
        >

        <router-link
          to="contatti"
          class="font-medium text-gray-500 hover:text-gray-900"
        >
          Contattaci
        </router-link>

        <router-link
          to="donazioni"
          class="font-medium text-blue-900 hover:text-blue-500"
          >Donazioni</router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
};
</script>
