import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../routes/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/chi-siamo",
    name: "Team",
    component: () => import("../routes/Team.vue"),
  },
  {
    path: "/pazienti",
    name: "Pazienti",
    component: () => import("../routes/Patients.vue"),
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: () => import("../routes/Testimonials.vue"),
  },
  {
    path: "/contatti",
    name: "Contacts",
    component: () => import("../routes/Contacts.vue"),
  },
  {
    path: "/patologie",
    name: "Patologies",
    component: () => import("../routes/Patologies.vue"),
  },
  {
    path: "/acalasia",
    name: "Acalasia",
    component: () => import("../patologies/Acalasia.vue"),
  },
  {
    path: "/barrett",
    name: "Barrett",
    component: () => import("../patologies/Barrett.vue"),
  },
  {
    path: "/reflusso",
    name: "Reflusso",
    component: () => import("../patologies/Reflusso.vue"),
  },

  {
    path: "/jatale",
    name: "Jatale",
    component: () => import("../patologies/Jatale.vue"),
  },
  {
    path: "/diverticoli",
    name: "Diverticoli",
    component: () => import("../patologies/Diverticoli.vue"),
  },
  {
    path: "/zenker",
    name: "Zenker",
    component: () => import("../patologies/Zenker.vue"),
  },
  {
    path: "/stomaco",
    name: "Stomaco",
    component: () => import("../patologies/Stomaco.vue"),
  },
  {
    path: "/esofago",
    name: "Esofago",
    component: () => import("../patologies/Esofago.vue"),
  },
  {
    path: "/gist",
    name: "Gist",
    component: () => import("../patologies/Gist.vue"),
  },
  {
    path: "/leiomioma",
    name: "Leiomioma",
    component: () => import("../patologies/Leiomioma.vue"),
  },
  {
    path: "/donazioni",
    name: "Donations",
    component: () => import("../routes/Donations.vue"),
  },
  {
    path: "/rosati",
    name: "Rosati",
    component: () => import("../members/Rosati.vue"),
  },
  {
    path: "/parise",
    name: "Parise",
    component: () => import("../members/Parise.vue"),
  },
  {
    path: "/elmore",
    name: "Elmore",
    component: () => import("../members/Elmore.vue"),
  },
  {
    path: "/cossu",
    name: "Cossu",
    component: () => import("../members/Cossu.vue"),
  },
  {
    path: "/puccetti",
    name: "Puccetti",
    component: () => import("../members/Puccetti.vue"),
  },

  {
    path: "/barbieri",
    name: "Barbieri",
    component: () => import("../members/Barbieri.vue"),
  },

  {
    path: "/girotto",
    name: "Girotto",
    component: () => import("../members/Girotto.vue"),
  },

  {
    path: "/serra",
    name: "Serra",
    component: () => import("../members/Serra.vue"),
  },
  {
    path: "/massaron",
    name: "Massaron",
    component: () => import("../members/Massaron.vue"),
  },
  {
    path: "/zangrillo",
    name: "Zangrillo",
    component: () => import("../members/Zangrillo.vue"),
  },
  {
    path: "/tettamanti",
    name: "Tettamanti",
    component: () => import("../members/Tettamanti.vue"),
  },
  {
    path: "/chiesa",
    name: "Chiesa",
    component: () => import("../members/Chiesa.vue"),
  },
  {
    path: "/cobelli",
    name: "Cobelli",
    component: () => import("../members/Cobelli.vue"),
  },
  {
    path: "/negri",
    name: "Negri",
    component: () => import("../members/Negri.vue"),
  },
  {
    path: "/tonon",
    name: "Tonon",
    component: () => import("../members/Tonon.vue"),
  },
  {
    path: "/bussi",
    name: "Bussi",
    component: () => import("../members/Bussi.vue"),
  },
  {
    path: "/cascinu",
    name: "Cascinu",
    component: () => import("../members/Cascinu.vue"),
  },
  {
    path: "/myreview",
    name: "Andrea",
    component: () => import("../reviews/reflusso/Andrea.vue"),
  },

  {
    path: "*",
    name: "HomeDefault",
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Centro Cerca e Ricerca - Ospedale San Raffaele Milano',
      metaTags: [
        {
          name: 'description',
          content: 'Centro Cerca e Ricerca a Milano offre alta specializzazione per diagnosi e trattamento delle malattie esofago-gastriche, con un focus forte sulla ricerca avanzata.'
        },
        {
          property: 'og:title',
          content: 'Centro Cerca e Ricerca - Ospedale San Raffaele Milano'
        },
        {
          property: 'og:description',
          content: 'Scopri il Centro Cerca e Ricerca di Milano: eccellenza nella cura e ricerca delle malattie dell’esofago e dello stomaco.'
        },
        {
          property: 'og:url',
          content: 'https://www.centroesofagohsr.it'
        },
        {
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  }
  
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
