<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white">
    <div
      class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"
    >
      <div>
        <h2
          class="text-base font-semibold tracking-wide text-blue-600 uppercase"
        >
          I nostri servizi
        </h2>
        <p class="mt-2 text-3xl font-extrabold text-gray-900">
          Affidati ai professionisti
        </p>
        <p class="mt-4 text-lg text-gray-500">
          Tutta la tecnologia più all’avanguardia è a disposizione del paziente
          per ottenere in tempi brevi e sotto un attento coordinamento e
          monitoraggio, le risposte adeguate ad indirizzarlo ad un programma di
          terapia personalizzato per offrire il più alto livello di cura
          possibile.
        </p>
      </div>
      <div class="mt-12 lg:mt-0 lg:col-span-2">
        <dl
          class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8"
        >
          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Chirurgia Gastroenterologica
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Gastroenterologia, Endoscopia Digestiva ed Ecoendoscopia
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Oncologia Medica
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Radiologia
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Medicina Nucleare
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Fisiatria e Riabilitazione Motoria
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Nutrizione Clinica
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>

          <div class="flex">
            <!-- Heroicon name: check -->
            <svg
              class="flex-shrink-0 w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div class="ml-3">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                Pneumologia e Fisiopatologia Respiratoria
              </dt>
              <dd class="mt-2 text-base text-gray-500"></dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureSection",
};
</script>
