<template>
  <div id="app">
    <router-view />
    <!-- <Home /> -->
    <!-- <Contacts /> -->
  </div>
</template>

<script>
// import Home from "./routes/Home.vue";
// import TextPage from "./routes/TextPage.vue";
// import Contacts from "./routes/Contacts.vue";

export default {
  name: "App",
  components: {
    // Home,
    // Contacts,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
