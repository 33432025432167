<template>
  <div
    :class="{ hidden: !isShowing }"
    class="fixed bottom-0 inset-x-0 pb-2 z-50"
  >
    <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-blue-400 shadow">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <p
              class="ml-3 mr-2 sm:mr-0 font-normal text-xs sm:text-sm text-white"
            >
              <span class="md:inline"
                >Il nostro sito utilizza cookies per migliorare le funzionalità
                del servizio.</span
              >
            </p>
          </div>
          <div class="flex-shrink-0 sm:ml-2">
            <button
              type="button"
              class="-mr-1 flex p-2 rounded-md focus:outline-none transition ease-in-out duration-150"
              aria-label="Dismiss"
              @click="dismiss"
            >
              <svg
                class="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      isShowing: true,
    };
  },
  created() {
    const consent = !localStorage.getItem("consent");
    console.log(consent);
    if (consent !== undefined) {
      this.isShowing = consent;
    }
  },
  methods: {
    dismiss() {
      this.isShowing = false;
      localStorage.setItem("consent", true);
    },
  },
};
</script>
