<template>
  <!--
        Mobile menu, show/hide based on menu open state.

        Entering: "duration-150 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
  <div
    :class="openClass"
    class="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden"
  >
    <div
      class="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5"
    >
      <div class="flex items-center justify-between px-5 pt-4">
        <div>
          <router-link to="/">
            <img class="w-auto h-8" src="@/assets/icon.png" alt="" />
          </router-link>
        </div>
        <div class="-mr-2">
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            @click="open"
          >
            <span class="sr-only">Close main menu</span>
            <!-- Heroicon name: x -->
            <svg
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-if="isOpen"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="main-menu"
      >
        <div class="px-2 pt-2 pb-3 space-y-1" role="none">
          <router-link
            to="chi-siamo"
            class="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            role="menuitem"
            >Chi siamo</router-link
          >

          <router-link
            to="patologie"
            class="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            role="menuitem"
            >Patologie</router-link
          >

          <router-link
            to="contatti"
            class="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            role="menuitem"
            >Contattaci</router-link
          >

          <router-link
            to="testimonials"
            class="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
            role="menuitem"
            >Testimonianze</router-link
          >
        </div>
        <div role="none">
          <router-link
            to="donazioni"
            class="block w-full px-5 py-3 text-base font-medium text-gray-100 bg-blue-900 hover:bg-blue-600"
            role="menuitem"
          >
            Donazioni
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    openClass() {
      if (this.isOpen) {
        return "opacity-100 scale-100";
      } else {
        return "opacity-0 scale-95";
      }
    },
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
